import { Close } from '@air/next-icons';
import Link from 'next/link';
import { memo } from 'react';

import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { createBoardRoute } from '~/constants/routes';
import { DuplicateBoardTask, GetCompletedMetadata } from '~/store/tasks/types';

import { CompletedAvatar } from '../FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { StatusTrackingSubtitle } from '../FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '../FileStatusTrackingPane/StatusTrackingTitle';

export type DuplicateBoardCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<DuplicateBoardTask>;
  onClear: () => void;
};

export const DuplicateBoardCompletedPanelItem = memo(
  ({ metadata: { sourceBoardName, duplicatedRootBoards }, onClear }: DuplicateBoardCompletedPanelItemProps) => {
    const boardId = duplicatedRootBoards?.[0]?.id;
    const boardTitle = duplicatedRootBoards?.[0]?.title;
    const boardUrl = boardId ? createBoardRoute(boardId, boardTitle) : null;

    return (
      <PaneItemTW
        truncate={false}
        avatar={<CompletedAvatar />}
        title={
          <StatusTrackingTitle data-testid="DUPLICATED_SUCESSFULY_TITLE">Duplicated successfully</StatusTrackingTitle>
        }
        subtitle={
          <StatusTrackingSubtitle className="truncate">
            <>
              Board &quot;
              {boardUrl ? (
                <Link className="font-medium underline" href={boardUrl} rel="noopener noreferrer">
                  {boardTitle}
                </Link>
              ) : (
                { sourceBoardName }
              )}
              &quot; is ready to use.
            </>
          </StatusTrackingSubtitle>
        }
        buttons={<PaneButton label="Clear" Icon={Close} onClick={() => onClear()} />}
      />
    );
  },
);

DuplicateBoardCompletedPanelItem.displayName = 'DuplicateBoardCompletedPanelItem';
