import { Color, ColorPicker, ColorPickerProps } from '@air/primitive-color-picker';
import { Popover, PopoverProps } from '@air/primitive-popover';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import classNames from 'classnames';
import { useState } from 'react';

export type ColorPickerMenuProps = Pick<PopoverProps, 'align' | 'className' | 'open'> &
  Pick<ColorPickerProps, 'colors'> & {
    selectedColor?: Color;
    onSelect?: (color: Color) => void;
  };

export const ColorPickerMenu = ({
  align = 'end',
  className,
  colors,
  onSelect,
  open,
  selectedColor,
  ...restOfProps
}: ColorPickerMenuProps) => {
  const [isOpened, setIsOpened] = useState(open);

  return (
    <Popover
      align={align}
      className={classNames('min-w-0', className)}
      onOpenChange={(open) => setIsOpened(open)}
      open={isOpened}
      sideOffset={8}
      trigger={
        <button className="flex size-8 items-center justify-center rounded border border-grey-4 bg-grey-1">
          <div
            className={classNames(
              'h-4 w-4 rounded-sm bg-grey-3',
              selectedColor?.hex === '#FFFFFF' ? 'border-gray-4 border' : 'border-none',
            )}
            style={{ backgroundColor: selectedColor?.hex }}
          />
          <VisuallyHidden>{selectedColor?.name || 'Unselected'}</VisuallyHidden>
        </button>
      }
      {...restOfProps}
    >
      <ColorPicker
        colors={colors}
        onSelect={(color) => {
          setIsOpened(false);
          onSelect?.(color);
        }}
      />
    </Popover>
  );
};
