import { Banner } from '@air/primitive-banner';
import React, { memo } from 'react';

interface UploadDisabledOnPageWarningProps {
  title: string;
  message: string;
}

export const UploadDisabledOnPageWarning = memo(({ message, title }: UploadDisabledOnPageWarningProps) => (
  <Banner appearance="warning">
    <p className="font-medium">{title}</p>
    <p>{message}</p>
  </Banner>
));

UploadDisabledOnPageWarning.displayName = 'UploadDisabledOnPageWarning';
