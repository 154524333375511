import classNames from 'classnames';
import { truncate } from 'lodash';
import { memo, PropsWithChildren } from 'react';

type EllipsisInMiddleProps = JSX.IntrinsicElements['div'] & {
  text: string;
};

export const EllipsisInMiddle = memo(({ text, className, ...restProps }: PropsWithChildren<EllipsisInMiddleProps>) => {
  return (
    <div className={classNames('flex w-full', className)} {...restProps}>
      <div className="shrink-0">{truncate(text, { length: 12, omission: '' })}</div>
      {text.length > 12 && (
        <div className="truncate" dir="rtl">
          <span dir="ltr">{text.slice(12)}</span>
        </div>
      )}
    </div>
  );
});

EllipsisInMiddle.displayName = 'EllipsisInMiddle';
