import { ChevronDown } from '@air/next-icons';
import { memo } from 'react';

import { ProgressingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { AssetRestorationTask, GetInProgressMetadata } from '~/store/tasks/types';

import { EllipsisInMiddle } from '../EllipsisInMiddle';
import { StatusTrackingSubtitle } from '../FileStatusTrackingPane/StatusTrackingSubtitle';

export type RestoreAssetTaskInProgressPanelItemProps = {
  metadata: Pick<GetInProgressMetadata<AssetRestorationTask>, 'assets'>;
  onCancel: () => void;
};

export const RestoreAssetTaskInProgressPanelItem = memo(
  ({ onCancel, metadata: { assets } }: RestoreAssetTaskInProgressPanelItemProps) => {
    return (
      <PaneItemTW
        avatar={<ProgressingAvatar />}
        title={<StatusTrackingTitle>Recovering</StatusTrackingTitle>}
        subtitle={
          <StatusTrackingSubtitle className="flex text-nowrap">
            {assets.length === 1 ? (
              <>
                <span className="flex-none pr-1">Recovering asset: </span>
                <EllipsisInMiddle className="font-bold" text={assets[0].title || ''} />
              </>
            ) : (
              <span className="truncate">Recovering {assets.length} assets</span>
            )}
          </StatusTrackingSubtitle>
        }
        buttons={<PaneButton label="Hide" Icon={ChevronDown} onClick={onCancel} />}
      />
    );
  },
);

RestoreAssetTaskInProgressPanelItem.displayName = 'RestoreAssetTaskInProgressPanelItem';
