import { ChevronDown } from '@air/next-icons';
import { memo } from 'react';

import { ProgressingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { GetInProgressMetadata, LibraryRestorationTask } from '~/store/tasks/types';

import { EllipsisInMiddle } from '../EllipsisInMiddle';
import { StatusTrackingSubtitle } from '../FileStatusTrackingPane/StatusTrackingSubtitle';

export type RestoreLibraryPanelInProgressPanelItemProps = {
  metadata: Pick<GetInProgressMetadata<LibraryRestorationTask>, 'title'>;
  onCancel: () => void;
};

export const RestoreLibraryPanelInProgressPanelItem = memo(
  ({ onCancel, metadata: { title } }: RestoreLibraryPanelInProgressPanelItemProps) => {
    return (
      <PaneItemTW
        avatar={<ProgressingAvatar />}
        title={<StatusTrackingTitle>Recovering library</StatusTrackingTitle>}
        subtitle={
          <StatusTrackingSubtitle className="flex text-nowrap">
            <span className="flex-none pr-1">Recovering library: </span>
            <EllipsisInMiddle className="font-bold" text={title || ''} />
          </StatusTrackingSubtitle>
        }
        buttons={<PaneButton label="Hide" Icon={ChevronDown} onClick={onCancel} />}
      />
    );
  },
);

RestoreLibraryPanelInProgressPanelItem.displayName = 'RestoreLibraryPanelInProgressPanelItem';
