import { useTrackClickedSidebarNavigationItem } from '@air/analytics';
import { SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { Trash } from '@air/next-icons';
import { Tooltip } from '@air/primitive-tooltip';
import Link from 'next/link';
import { useCallback } from 'react';

import { Routes } from '~/constants/routes';
import { useIsActiveRoute } from '~/hooks/useIsActiveRoute';

const ITEM_NAME = 'Recently deleted';

export const SidebarNavigationItemRecentlyDeleted = () => {
  const { isActiveRoute } = useIsActiveRoute({ route: Routes.media.recentlyDeleted });
  const { trackClickedSidebarNavigationItem } = useTrackClickedSidebarNavigationItem();

  const onClick = useCallback(() => {
    trackClickedSidebarNavigationItem({
      item_name: ITEM_NAME,
    });
  }, [trackClickedSidebarNavigationItem]);

  return (
    <Tooltip label="Recently deleted items" side="right" sideOffset={4}>
      <SidebarNavigationItem
        asChild
        data-id="recently-deleted"
        prefix={<Trash className="size-4" />}
        state={isActiveRoute ? 'active' : 'default'}
        onClick={onClick}
      >
        <Link href={Routes.media.recentlyDeleted}>{ITEM_NAME}</Link>
      </SidebarNavigationItem>
    </Tooltip>
  );
};
