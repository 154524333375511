import { Close } from '@air/next-icons';
import { memo } from 'react';

import { ErroredAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';

export type RestoreBoardFailedPanelItemProps = {
  onClear: () => void;
};

export const RestoreBoardFailedPanelItem = memo(({ onClear }: RestoreBoardFailedPanelItemProps) => {
  return (
    <PaneItemTW
      avatar={<ErroredAvatar />}
      title={<StatusTrackingTitle>Failed to recover board!</StatusTrackingTitle>}
      buttons={<PaneButton label="Clear" Icon={Close} onClick={onClear} />}
    />
  );
});

RestoreBoardFailedPanelItem.displayName = 'RestoreBoardFailedPanelItem';
