import { SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { BoardMove } from '@air/next-icons';
import { Tooltip } from '@air/primitive-tooltip';
import Link from 'next/link';

import { Routes } from '~/constants/routes';
import { useIsActiveRoute } from '~/hooks/useIsActiveRoute';

export const SidebarNavigationItemUnusedItems = () => {
  const { isActiveRoute } = useIsActiveRoute({ route: Routes.media.unusedItems });

  return (
    <Tooltip label="Items that do not belong to any boards in your workspace." side="right">
      <SidebarNavigationItem
        asChild
        data-id="unused-items"
        prefix={<BoardMove className="size-4" />}
        state={isActiveRoute ? 'active' : 'default'}
      >
        <Link href={Routes.media.all}>Unused items</Link>
      </SidebarNavigationItem>
    </Tooltip>
  );
};
