import { Close } from '@air/next-icons';
import Link from 'next/link';
import { memo } from 'react';

import { CompletedAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { Routes } from '~/constants/routes';
import { useLaunchDarklyFlags } from '~/hooks/useLaunchDarklyFlags';
import { GetCompletedMetadata, LibraryDeletionTask } from '~/store/tasks/types';

import { StatusTrackingSubtitle } from '../FileStatusTrackingPane/StatusTrackingSubtitle';

export type DuplicateBoardCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<LibraryDeletionTask>;
  onClear: () => void;
};

export const DeleteLibraryCompletedPanelItem = memo(({ onClear }: DuplicateBoardCompletedPanelItemProps) => {
  const { deletionRecovery } = useLaunchDarklyFlags();

  return (
    <PaneItemTW
      truncate={false}
      avatar={<CompletedAvatar />}
      title={<StatusTrackingTitle>Deleted successfully</StatusTrackingTitle>}
      subtitle={
        deletionRecovery ? (
          <StatusTrackingSubtitle className="truncate">
            Library moved to{' '}
            <Link href={Routes.media.recentlyDeleted} className="cursor-pointer text-14 text-blue-7">
              Recently deleted
            </Link>
          </StatusTrackingSubtitle>
        ) : (
          <StatusTrackingSubtitle className="truncate">Library deleted</StatusTrackingSubtitle>
        )
      }
      buttons={<PaneButton label="Clear" Icon={Close} onClick={onClear} />}
    />
  );
});

DeleteLibraryCompletedPanelItem.displayName = 'DeleteLibraryCompletedPanelItem';
