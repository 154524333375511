import { Close } from '@air/next-icons';
import Link from 'next/link';
import pluralize from 'pluralize';
import { memo } from 'react';

import { CompletedAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingSubtitle } from '~/components/FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { Routes } from '~/constants/routes';
import { useLaunchDarklyFlags } from '~/hooks/useLaunchDarklyFlags';
import { BoardDeletionTask, GetCompletedMetadata } from '~/store/tasks/types';

export type DeleteBoardCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<BoardDeletionTask>;
  onClear: () => void;
};

export const DeleteBoardCompletedPanelItem = memo(
  ({ metadata: { boardIds }, onClear }: DeleteBoardCompletedPanelItemProps) => {
    const { deletionRecovery } = useLaunchDarklyFlags();

    return (
      <PaneItemTW
        truncate={false}
        avatar={<CompletedAvatar />}
        title={<StatusTrackingTitle>Deleted successfully</StatusTrackingTitle>}
        subtitle={
          deletionRecovery ? (
            <StatusTrackingSubtitle className="truncate">
              {pluralize('Board', boardIds.length, true)} moved to{' '}
              <Link href={Routes.media.recentlyDeleted} className="cursor-pointer text-14 text-blue-7">
                Recently deleted
              </Link>
            </StatusTrackingSubtitle>
          ) : (
            <StatusTrackingSubtitle className="truncate">
              {`${pluralize('board', boardIds.length, true)} deleted`}
            </StatusTrackingSubtitle>
          )
        }
        buttons={<PaneButton label="Clear" Icon={Close} onClick={onClear} />}
      />
    );
  },
);

DeleteBoardCompletedPanelItem.displayName = 'DeleteBoardCompletedPanelItem';
