import { Assets } from '@air/api';
import { InfiniteData } from '@tanstack/react-query';
import { memo, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useSocketConnectionChange } from '~/providers/SocketContext/hooks/useSocketConnectionChange';
import { removeTasksAction } from '~/store/tasks/actions';
import { makeRestoreAssetTasksByWorkspaceId } from '~/store/tasks/selectors';
import { queryClient } from '~/swr-hooks/queryClient';
import { ReadyState } from '~/types/sockets';
import { getAssetGalleryItemType } from '~/utils/AssetUtils';
import { useAddAssetsToAllViews } from '~/utils/mutateUtils/GalleryAssets';
import { useAirSelector } from '~/utils/ReduxUtils';
import { useCancelTask } from '~/utils/taskUtils/useCancelTask';
import { useHideTask } from '~/utils/taskUtils/useHideTask';
import { usePrivateSyncTasks } from '~/utils/taskUtils/usePrivateSyncTasks';

import { PaneContainer } from '../FileStatusTrackingPane/PaneContainer';
import { GalleryItemType } from '../Gallery/types';
import { isRecentlyDeletedKey } from '../RecentlyDeleted/hooks/queries/useRecentlyDeleted';
import { RecentlyDeletedResponse } from '../RecentlyDeleted/types';
import { RestoreAssetTaskCompletedPanelItem } from './RestoreAssetTaskCompletedPanelItem';
import { RestoreAssetTaskFailedPanelItem } from './RestoreAssetTaskFailedPanelItem';
import { RestoreAssetTaskInProgressPanelItem } from './RestoreAssetTaskInProgressPanelItem';

export const PrivateRestoreAssetTasksPanel = memo(() => {
  const { currentWorkspace } = useCurrentWorkspace();
  const tasksSelector = useMemo(() => makeRestoreAssetTasksByWorkspaceId(currentWorkspace?.id), [currentWorkspace?.id]);
  const dispatch = useDispatch();
  const restoreAssetTasks = useAirSelector(tasksSelector);
  const { cancelTask } = useCancelTask();
  const { hideTask } = useHideTask();
  const { addAssetsToAllViews } = useAddAssetsToAllViews();

  const { loadFromStorage, syncLocalTasks } = usePrivateSyncTasks({
    workspaceId: currentWorkspace?.id,
    tasksSelector,
    localType: 'AssetRestoration',
    remoteType: 'ContentRestorer',
    onComplete: async ({ task }) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      queryClient.setQueriesData<InfiniteData<RecentlyDeletedResponse> | undefined>(
        {
          predicate: ({ queryKey }) => {
            return isRecentlyDeletedKey(queryKey) && queryKey[1].workspaceId === currentWorkspace?.id;
          },
        },
        (oldData) => {
          if (!oldData) {
            return oldData;
          }

          return {
            ...oldData,
            pages: oldData.pages.map((page) => {
              return {
                ...page,
                data: page.data.filter(
                  (item) => item.itemType !== 'asset' || !task.data?.restoredAssetIds?.includes(item.asset.id),
                ),
              };
            }),
          };
        },
      );
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          return isRecentlyDeletedKey(queryKey) && queryKey[1].workspaceId === currentWorkspace?.id;
        },
      });

      if (!task.data?.restoredAssetIds?.length) {
        return;
      }

      const restoredAssets = await Assets.listCoverVersionsByAssetIds({
        assetIds: task.data.restoredAssetIds,
        workspaceId,
        withBoards: true,
      });

      const assetsGroupedByType = restoredAssets.reduce((acc, asset) => {
        const type = getAssetGalleryItemType(asset);
        if (!acc.has(type)) {
          acc.set(type, []);
        }
        acc.get(type)?.push(asset);
        return acc;
      }, new Map<GalleryItemType.asset | GalleryItemType.file, typeof restoredAssets>());

      assetsGroupedByType.forEach((group, type) => {
        const assetsGroupedByParent = group.reduce((acc, asset) => {
          asset.boards.forEach((board) => {
            if (!acc.has(board.id)) {
              acc.set(board.id, []);
            }
            acc.get(board.id)?.push(asset);
          });

          return acc;
        }, new Map<string, typeof restoredAssets>());

        assetsGroupedByParent.forEach((assets, parentId) => {
          addAssetsToAllViews({
            parentBoardId: parentId,
            assets: assets.map((asset) => ({
              ...asset,
              altResolutions: [],
              bookmarked: false,
              smartTags: [],
            })),
            type,
          });
        });
      });
    },
  });

  /**
   * On mount, take the tasks from local storage and add them to Redux
   */
  useEffect(() => {
    if (currentWorkspace?.id) {
      loadFromStorage();
    }
  }, [loadFromStorage, currentWorkspace?.id]);

  /**
   * Because sockets reconnect when the user's internet connection comes back online,
   * we don't need an explicit isOnline check but can piggyback off of the socket reconnecting
   */
  useSocketConnectionChange({
    onChange: (readyState) => {
      if (readyState === ReadyState.OPEN) {
        syncLocalTasks();
      }
    },
  });

  /**
   * When this panel unmounts (user logs out most likely),
   * reset the state
   */
  useUnmount(() => {
    dispatch(
      removeTasksAction({
        taskIds: restoreAssetTasks.map(({ localTaskId }) => localTaskId),
      }),
    );
  });

  if (restoreAssetTasks.length < 1) {
    return null;
  }

  return (
    <div>
      {restoreAssetTasks.map((task) => {
        return (
          <PaneContainer key={task.localTaskId} className="mb-2 last:mb-0">
            {!task.hidden && task.status === 'in-progress' && (
              <RestoreAssetTaskInProgressPanelItem onCancel={() => hideTask(task.localTaskId)} {...task} />
            )}
            {task.status === 'completed' && (
              <RestoreAssetTaskCompletedPanelItem onClear={() => cancelTask(task.localTaskId)} {...task} />
            )}
            {task.status === 'error' && (
              <RestoreAssetTaskFailedPanelItem onClear={() => cancelTask(task.localTaskId)} {...task} />
            )}
          </PaneContainer>
        );
      })}
    </div>
  );
});

PrivateRestoreAssetTasksPanel.displayName = 'PrivateRestoreAssetTasksPanel';
