import { ChevronDown } from '@air/next-icons';
import pluralize from 'pluralize';
import { memo } from 'react';

import { ProgressingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { BoardDeletionTask, GetInProgressMetadata } from '~/store/tasks/types';

export type DeleteBoardPanelInProgressPanelItemProps = {
  metadata: Pick<GetInProgressMetadata<BoardDeletionTask>, 'boardIds'>;
  onCancel: () => void;
};

export const DeleteBoardPanelInProgressPanelItem = memo(
  ({ onCancel, metadata: { boardIds } }: DeleteBoardPanelInProgressPanelItemProps) => {
    return (
      <PaneItemTW
        avatar={<ProgressingAvatar />}
        title={<StatusTrackingTitle>{`Deleting ${pluralize('board', boardIds.length, true)}`}</StatusTrackingTitle>}
        buttons={<PaneButton label="Hide" Icon={ChevronDown} onClick={onCancel} />}
      />
    );
  },
);

DeleteBoardPanelInProgressPanelItem.displayName = 'DeleteBoardPanelInProgressPanelItem';
