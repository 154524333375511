'use client';
import { Close } from '@air/next-icons';
import { Portal } from '@air/primitive-portal';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import { animated, config, useTransition } from '@react-spring/web';
import { type ComponentProps, type ReactNode, useRef } from 'react';

import { useMobileNavigationContext } from './MobileNavigationContext';

export const MOBILE_NAVIGATION_HEIGHT = 64;

export type MobileNavigationItem = {
  icon: ReactNode;
  id: string;
  label: string;
} & (
  | { renderContent: ({ onClose }: { onClose: () => void }) => ReactNode; onClick?: never }
  | (Required<Pick<ComponentProps<'button'>, 'onClick'>> & {
      renderContent?: never;
    })
);

export type MobileNavigationProps = ComponentProps<'div'> & {
  items: MobileNavigationItem[];
};

export const MobileNavigation = ({ items }: MobileNavigationProps) => {
  const { activePanel, closePanel, togglePanel } = useMobileNavigationContext();
  const containerRef = useRef<HTMLDivElement>(null);

  const transitions = useTransition(activePanel, {
    from: { transform: 'translateY(100%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(100%)' },
    config: config.default,
  });

  return (
    <Portal>
      <div
        className="fixed inset-x-0 bottom-0"
        data-testid="MOBILE_NAVIGATION"
        /**
         * This ref is used for panels to be rendered inside the container.
         * The reason we want to render the panels inside the container is to avoid
         * z-index issues with the rest of the app.
         */
        ref={containerRef}
      >
        <div
          className="relative z-0 flex items-center justify-between gap-4 border-t border-t-grey-5 bg-surface-1 px-4"
          style={{ height: MOBILE_NAVIGATION_HEIGHT }}
        >
          {transitions((style, item) => {
            const panel = items?.find((i) => i.id === item);

            if (!items.length || !(panel && 'renderContent' in panel)) {
              return null;
            }

            return (
              <Portal container={containerRef.current}>
                <animated.div
                  className="fixed inset-0 -z-1 size-full bg-surface-1 will-change-transform"
                  data-testid="MOBILE_NAVIGATION_PANEL"
                  key={item}
                  style={{ ...style, height: `calc(100% - ${MOBILE_NAVIGATION_HEIGHT}px)` }}
                >
                  {panel?.renderContent?.({ onClose: closePanel })}
                </animated.div>
              </Portal>
            );
          })}

          {items.map((item) => {
            const isActive = activePanel === item.id;

            return (
              <button
                className="flex w-full cursor-pointer flex-col items-center justify-center gap-1 text-8 text-grey-9 hover:text-grey-12"
                data-testid="MOBILE_NAVIGATION_ITEM"
                key={item.id}
                onClick={(event) => {
                  if ('onClick' in item && item.onClick) {
                    item.onClick(event);
                    closePanel();
                  } else {
                    togglePanel(item.id);
                  }
                }}
              >
                {isActive ? <Close className="size-6" /> : item.icon}
                {!!item.label && <VisuallyHidden>{item.label}</VisuallyHidden>}
              </button>
            );
          })}
        </div>
      </div>
    </Portal>
  );
};
