import { Close } from '@air/next-icons';
import { memo } from 'react';

import { CompletedAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { GetCompletedMetadata, LibraryRestorationTask } from '~/store/tasks/types';

import { EllipsisInMiddle } from '../EllipsisInMiddle';
import { StatusTrackingSubtitle } from '../FileStatusTrackingPane/StatusTrackingSubtitle';

export type RestoreLibraryCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<LibraryRestorationTask>;
  onClear: () => void;
};

export const RestoreLibraryCompletedPanelItem = memo(
  ({ metadata: { title }, onClear }: RestoreLibraryCompletedPanelItemProps) => {
    return (
      <PaneItemTW
        truncate={false}
        avatar={<CompletedAvatar />}
        title={<StatusTrackingTitle>Recovered successfully</StatusTrackingTitle>}
        subtitle={
          <StatusTrackingSubtitle className="flex text-nowrap">
            <span className="flex-none pr-1">Recovered library: </span>
            <EllipsisInMiddle className="font-bold" text={title || ''} />
          </StatusTrackingSubtitle>
        }
        buttons={<PaneButton label="Clear" Icon={Close} onClick={onClear} />}
      />
    );
  },
);

RestoreLibraryCompletedPanelItem.displayName = 'RestoreLibraryCompletedPanelItem';
